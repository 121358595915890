import model from './model';
import { CategoriesGalleryWidgetComponentIds as ComponentIds } from './config/constants';
import { CategoriesGalleryWidgetProps as Props } from './config/types';
import { Category } from '@wix/ambassador-portfolio-categories-v1-category/build/es/types';
import { commonImagesToWixCode } from '../../converters/mediaConverter';

export default model.createController((controllerParams) => {
  const { $w, $widget, flowAPI } = controllerParams;
  let components: ReturnType<typeof getComponents>;

  const getComponents = () => ({
    topContainer: $w(ComponentIds.Root),
    galleryWidget: $w(ComponentIds.GalleyWIdget),
  });

  const getWixCodePGItems = (categories: Category[]) => {
    /// @ts-expect-error
    const routerPrefix = controllerParams?.controllerConfig?.appParams?.appRouters[0].prefix;
    const categoryCoverImages = categories.map((category: Category) => ({
      link: controllerParams.controllerConfig.wixCodeApi.location.baseUrl + `/${routerPrefix}/${category.id}`,
      ...category.coverImage?.imageInfo,
    }))
    return commonImagesToWixCode(categoryCoverImages);
  };

  const initByProps = (props: Props) => {
    const { data } = props;
    if (data.categories.length !== 0) {
      components.galleryWidget.items = getWixCodePGItems(data.categories);
    }
  };

  return {
    pageReady: async () => {
      components = getComponents();
      initByProps($widget.props);
      $widget.onPropsChanged((_, nextProps) => initByProps(nextProps));
      $widget.fireEvent('widgetLoaded', {});
    },
    exports: {},
  };
});
