export const CATEGORY_SLUG_PATH_INDEX = 0;
export const PROJECT_SLUG_PATH_INDEX = 1;

export const getPortfolioBaseUrl = (flowAPI) => {
  return `${flowAPI.controllerConfig.wixCodeApi.location.baseUrl}/${flowAPI.controllerConfig.wixCodeApi.location.prefix}`;
};

export const getCategoryUrl = (flowAPI, categorySlug) => {
  return `${getPortfolioBaseUrl(flowAPI)}/${categorySlug}`;
};

export const getProjectUrl = (flowAPI, categorySlug, projectSlug) => {
  return `${getCategoryUrl(flowAPI, categorySlug)}/${projectSlug}`;
};

export const getCategorySlugFromUrl = (flowAPI) => {
  return flowAPI.controllerConfig.wixCodeApi.location.path[CATEGORY_SLUG_PATH_INDEX];
};

export const getProjectSlugFromUrl = (flowAPI) => {
  return flowAPI.controllerConfig.wixCodeApi.location.path[PROJECT_SLUG_PATH_INDEX];
};
