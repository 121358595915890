import model from './model';
import { CategoryWidgetComponentIds as ComponentIds } from './config/constants';
import { CategoryWidgetProps as Props } from './config/types';
import { Project } from '@wix/ambassador-portfolio-projects-v1-project/build/es/types';
import { commonImagesToWixCode } from '../../converters/mediaConverter';

export default model.createController((controllerParams) => {
  const { $w, $widget, flowAPI } = controllerParams;
  let components: ReturnType<typeof getComponents>;
  const getComponents = () => ({
    topContainer: $w(ComponentIds.Root),
    title: $w(ComponentIds.Title),
    description: $w(ComponentIds.Description),
    gallery: $w(ComponentIds.Gallery),
  });
  const getWixCodePGItems = (projects: Project[], categorySlug) => {
    /// @ts-expect-error
    const routerPrefix = controllerParams?.controllerConfig?.appParams?.appRouters[0].prefix;
    const projectCoverImages = projects.map((project: Project) => ({
      link: controllerParams.controllerConfig.wixCodeApi.location.baseUrl + `/${routerPrefix}/${categorySlug}/${project.id}`,
      ...project.coverImage?.imageInfo,
    }))
    return commonImagesToWixCode(projectCoverImages);
  };

  const initByProps = (props: Props) => {
    const { data } = props;
    if (data.category.id) {
      components.title.text = data.category.title;
      components.description.text = data.category.description;
      components.gallery.items = getWixCodePGItems(
        data.projects,
        data.category.id,
      );
    }
  };

  return {
    pageReady: async () => {
      components = getComponents();
      initByProps($widget.props);
      $widget.onPropsChanged((_, nextProps) => initByProps(nextProps));
      $widget.fireEvent('widgetLoaded', {});
    },
    exports: {},
  };
});
