import model from './model';
import { ProjectHeaderWidgetComponentIds as ComponentIds } from './config/constants';
import { ProjectHeaderWidgetProps as Props } from './config/types';
import { get } from 'lodash';

export default model.createController((controllerParams) => {
  const { $w, $widget } = controllerParams;
  let components: ReturnType<typeof getComponents>;

  const getComponents = () => ({
    topContainer: $w(ComponentIds.Root),
    title: $w(ComponentIds.Title),
    subtitle: $w(ComponentIds.SubTitle),
    description: $w(ComponentIds.SubTitle),
    image: $w(ComponentIds.HeroImage),
  });
  const getDetailSubtitle = (details) => {
    if (!Array.isArray(details)) {
      return;
    }
    let subtitle = '';
    details.forEach((item) => {
      const { key, text } = item;
      subtitle += `${key}: ${text || ''} \n`;
    });
    return subtitle;
  };
  const initByProps = (props: Props) => {
    const { data } = props;
    components.title.text = data.title;
    components.subtitle.text = getDetailSubtitle(data.subtitle);
    // components.description = data.description;
    if (data.heroImage?.imageInfo?.url) {
      components.image.src = data.heroImage.imageInfo.url;
    }
  };

  return {
    pageReady: async () => {
      components = getComponents();
      initByProps($widget.props);
      $widget.onPropsChanged((_, nextProps) => initByProps(nextProps));
      $widget.fireEvent('widgetLoaded', {});
    },
    exports: {},
  };
});
