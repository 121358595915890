import model from './model';
import { CategoryPageWidgetComponentIds as ComponentIds } from './config/constants';
import { CategoryPageWidgetProps as Props } from './config/types';
import { listProjectsByCategory } from '@wix/ambassador-portfolio-projects-v1-project/http';
import { ProjectInCategory } from '@wix/ambassador-portfolio-projects-v1-project/types';
import { getCategory, queryCategory } from '@wix/ambassador-portfolio-categories-v1-category/http';
import { Category } from '@wix/ambassador-portfolio-categories-v1-category/build/es/types';
import { getCategorySlugFromUrl, getPortfolioBaseUrl } from '../../utils';

export default model.createController((controllerParams) => {
  const { $w, $widget, flowAPI } = controllerParams;

  let components: ReturnType<typeof getComponents>;
  let currCategory: Category = {};
  let projectsInCategory: ProjectInCategory[] = [];

  const getComponents = () => ({
    topContainer: $w(ComponentIds.Root),
    navigationWidget: $w(ComponentIds.NavigationWidget),
    categoryWidget: $w(ComponentIds.CategoryWidget),
  });

  const getCategoryBySlug = async () => {
    const categorySlug = getCategorySlugFromUrl(flowAPI);

    if (!categorySlug) {
      return {};
    }

    return (
      flowAPI.httpClient
        // @ts-expect-error
        .request(queryCategory({ query: { filter: { slug: categorySlug } } }))
        .then((res) => {
          const {
            data: { categories = [] },
          } = res;

          return categories[0];
        })
    );
  };

  const getProjects = async (categoryId) => {
    return (
      flowAPI.httpClient
        // @ts-expect-error
        .request(listProjectsByCategory({ categoryId }))
        .then((res) => {
          const {
            data: { projects = [] },
          } = res;

          return projects;
        })
    );
  };

  const initCategoryPage = async () => {
    currCategory = await getCategoryBySlug();
    if (currCategory.id) {
      projectsInCategory = await getProjects(currCategory.id);
      components.categoryWidget.data = {
        category: currCategory,
        projects: projectsInCategory.map(p => p.project),
      };

      components.navigationWidget.data = {
        hideNext: true,
        backButtonText: 'Back to Portfolio',
      };
    }
  };

  const handleInteractions = () => {
    // components.navigationWidget.onNextClicked(() => {
    //   console.log('next clicked ! category page widget');
    // });

    components.navigationWidget.onBackClicked(() => {
      // @ts-expect-error
      flowAPI.controllerConfig.wixCodeApi.location.to(
        getPortfolioBaseUrl(flowAPI),
      );
      console.log('back clicked ! category page widget');
    });
  };

  return {
    pageReady: async () => {
      components = getComponents();
      handleInteractions();
      await initCategoryPage();
      $widget.fireEvent('widgetLoaded', {});
    },
    exports: {},
  };
});
