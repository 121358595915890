import {
  Item,
  ItemType,
  ProjectItem,
} from '@wix/ambassador-portfolio-project-items-v1-project-item/build/cjs/types';
import _ from 'lodash';
import {
  ImageType,
  ProgalleryImage,
} from '@wix/ambassador-portfolio-projects-v1-project/build/cjs/types';

export interface ExtendedMediaItem {
  id?: string;
  name?: string;
  orderIndex?: number | null;
  fileName?: string;
  thumbnail?: string;
  dataType?: string;
  photoMetadata?: any;
  videoMetadata?: any;
  title?: string;
  description?: string;
  mediaUrl?: string;
  duration?: number;
}

export const convertToOrganizeMediaItems = (
  projectItems: ProjectItem[] = [],
): ExtendedMediaItem[] | undefined => {
  const items = _.sortBy(projectItems, (x) => x.item?.sortOrder)
    .filter((x: any) => x?.item)
    .map(({ item }: any) => {
      if (item.image) {
        return {
          id: item?.id,
          mediaUrl: item.image?.imageInfo?.url,
          fileName: item.image?.imageInfo?.filename,
          name: item.image?.imageInfo?.filename,
          orderIndex: item.sortOrder,
          thumbnail: item.thumbnail,
          dataType: 'Photo',
          photoMetadata: {
            width: item.image?.imageInfo?.width,
            height: item.image?.imageInfo?.height,
          },
          title: item.title,
          description: item.description,
        };
      } else {
        item.video?.videoInfo?.posters?.forEach((x: any) => {
          x.url = 'media/' + x.id;
        });
        return {
          id: item?.id,
          mediaUrl: item.video?.videoInfo?.url,
          fileName: item.video?.videoInfo?.posters[0].filename,
          name: item.video?.videoInfo?.filename,
          orderIndex: item.sortOrder,
          thumbnail: item.thumbnail,
          dataType: 'Video',
          videoMetadata: {
            width:
              item.video?.videoInfo?.width ||
              item.video?.videoInfo?.posters[0].width,
            height:
              item.video?.videoInfo?.height ||
              item.video?.videoInfo?.posters[0].height,
            duration: item.video?.duration,
            posters: item.video?.videoInfo?.posters,
          },
          title: item.title,
          description: item.description,
        };
      }
    });
  return items;
};

export const convertToOrganizeMediaItem = (
  item: any,
): ExtendedMediaItem | undefined => {
  const result = convertToOrganizeMediaItems([item]);
  if (result) {
    return result[0];
  }
};

export const convertCoverImageToOrganizeMediaItem = (
  item?: ProgalleryImage,
): ExtendedMediaItem => {
  return {
    name: item?.imageInfo?.filename || '',
    fileName: item?.imageInfo?.filename || '',
    dataType: 'PHOTO',
    photoMetadata: {
      width: item?.imageInfo?.width,
      height: item?.imageInfo?.height,
    },
  };
};

export const convertOrganizeMediaItemToCoverImage = (
  item?: ExtendedMediaItem,
): ProgalleryImage => {
  return {
    type: ImageType.WIX,
    imageInfo: {
      id: item?.name,
      filename: item?.name,
      height: item?.photoMetadata?.height,
      width: item?.photoMetadata?.width,
      altText: item?.title,
    },
  };
};

export const convertOrganizeMediaItemsToProjectItems = (
  extendedItems: ExtendedMediaItem[] = [],
): Item[] | undefined => {
  const items: Item[] = extendedItems.map(({ item }: any) => {
    return {
      id: item.image?.imageInfo?.filename,
      sortOrder: item.sortOrder,
      title: item.title,
      description: item.description,
      type: ItemType.IMAGE,
      secure: false,
      image: {
        type: ImageType.WIX,
        imageInfo: {
          id: item.image?.imageInfo?.filename,
          filename: item.image?.imageInfo?.filename,
          width: item.image?.imageInfo?.width,
          height: item.image?.imageInfo?.height,
          altText: item.title,
        },
      },
    };
  });
  return items;
};

export const commonImagesToWixCode = (images: any[]) => {
  const wixCodeItems = images?.map((imageInfo?: any) => ({
    type: 'image',
    title: imageInfo?.altText,
    link: imageInfo.link,
    target: '_self',
    imageToken: '',
    src: `wix:image://v1/${imageInfo?.filename}/${imageInfo?.filename}#originWidth=${imageInfo?.width}&originHeight=${imageInfo?.height}`,
  }));
  return wixCodeItems;
};
