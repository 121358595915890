import model from './model';
import {
  ProjectPageWidgetComponentIds as ComponentIds,
  ProjectPageWidgetStates as States,
} from './config/constants';
import { ProjectPageWidgetProps as Props } from './config/types';
import {
  getProject,
  queryProject,
  listProjectsByCategory,
} from '@wix/ambassador-portfolio-projects-v1-project/build/es/http';
import { Project } from '@wix/ambassador-portfolio-projects-v1-project/build/es/types';
import { listProjectItems } from '@wix/ambassador-portfolio-project-items-v1-project-item/http';
import { ProjectItem } from '@wix/ambassador-portfolio-project-items-v1-project-item/types';
import { getComponentSettings } from '@wix/ambassador-app-settings-v1-settings/http';
import { Host, State } from '@wix/ambassador-app-settings-v1-settings/types';
import { getCategorySlugFromUrl, getProjectSlugFromUrl } from '../../utils';
import {
  getCategory,
  queryCategory,
} from '@wix/ambassador-portfolio-categories-v1-category/http';
import { Category } from '@wix/ambassador-portfolio-categories-v1-category/build/es/types';

export default model.createController((controllerParams) => {
  const { $w, $widget, flowAPI } = controllerParams;

  const constProjectId = '05425b19-fe92-4047-9524-38ac581a564c';
  // const constProjectSlug = 'mockUrl';
  let components: ReturnType<typeof getComponents>;
  const currCategory: Category = {};
  let currProject: Project = {};
  let currProjectItems: ProjectItem[] = [];

  const getComponents = () => ({
    topContainer: $w(ComponentIds.Root),
    multiStateBox: $w(ComponentIds.MultiStateBox),
    projectWidget: $w(ComponentIds.ProjectWidget),
  });

  const getProjectBySlug = async () => {
    const projectSlug = getProjectSlugFromUrl(flowAPI);

    return (
      flowAPI.httpClient
        // @ts-expect-error
        .request(queryProject({ query: { filter: { slug: projectSlug } } }))
        .then((res) => {
          const {
            data: { projects = [] },
          } = res;

          return projects[0];
        })
    );
  };

  const getProjectById = async (projectId) => {
    if (!projectId) {
      return {};
    }
    return (
      flowAPI.httpClient
        // @ts-expect-error
        .request(getProject({ projectId }))
        .then((res) => {
          const {
            data: { project = {} },
          } = res;

          return project;
        })
    );
  };

  const getProjectItems = async (projectId) => {
    return (
      flowAPI.httpClient
        // @ts-expect-error
        .request(listProjectItems({ projectId }))
        .then((res) => {
          const {
            data: { projectItems = [] },
          } = res;

          return projectItems;
        })
    );
  };

  const getProjectLayoutState = () => {
    return flowAPI.httpClient
      .request(
        // @ts-expect-error
        getComponentSettings({
          state: State.SAVED,
          componentId: flowAPI.controllerConfig.appParams.instanceId,
          host: Host.VIEWER,
        }),
      )
      .then((res) => {
        return res.data.settings?.projectLayout || States.BASIC;
      });
  };

  const initProjectPage = async () => {
    const projectState =
      $widget.props.data.projectTemplate || (await getProjectLayoutState());
    console.log(projectState);
    components.multiStateBox.changeState(projectState);
    currProject = await getProjectById(constProjectId);
    if (currProject.id) {
      currProjectItems = await getProjectItems(currProject.id);
      components.projectWidget.data = {
        project: currProject,
        items: currProjectItems,
      };
    }
  };

  const initByProps = (props: Props) => {
    const { data } = props;
    if (data.projectTemplate) {
      console.log(data.projectTemplate);
      components.multiStateBox.changeState(data.projectTemplate);
      // save to appSettings?
    }
  };

  $widget.onPropsChanged((_, nextProps) => initByProps(nextProps));

  return {
    pageReady: async () => {
      components = getComponents();
      await initProjectPage();
      $widget.fireEvent('widgetLoaded', {});
    },
    exports: {},
  };
});
