import model from './model';
import {
  PortfolioPageWidgetComponentIds as ComponentIds,
  PortfolioPageWidgetStates as States,
} from './config/constants';
import { PortfolioPageWidgetProps as Props } from './config/types';
import { listCategories } from '@wix/ambassador-portfolio-categories-v1-category/http';
import { Category } from '@wix/ambassador-portfolio-categories-v1-category/types';
import { listProjectsByCategory } from '@wix/ambassador-portfolio-projects-v1-project/http';
import { ProjectInCategory } from '@wix/ambassador-portfolio-projects-v1-project/types';

export default model.createController((controllerParams) => {
  const { $w, $widget, flowAPI } = controllerParams;

  let components: ReturnType<typeof getComponents>;
  let categories: Category[] = [];

  const getComponents = () => ({
    topContainer: $w(ComponentIds.Root),
    multiStateBox: $w(ComponentIds.MultiState),
    galleryWidget: $w(ComponentIds.GalleryWidget),
    listWidget: $w(ComponentIds.ListWidget),
    categoryWidget: $w(ComponentIds.CategoryWidget),
  });

  const getCategories = async () => {
    // @ts-expect-error
    return flowAPI.httpClient.request(listCategories({})).then((res) => {
      const {
        data: { categories = [] },
      } = res;
      return categories;
    });
  };

  const getCategoryProjects = async (categoryId) => {
    return (
      flowAPI.httpClient
        // @ts-expect-error
        .request(listProjectsByCategory({ categoryId }))
        .then((res) => {
          const {
            data: { projects = [] },
          } = res;
          return projects;
        })
    );
  };

  const initPortfolio = async () => {
    categories = await getCategories();

    if (!categories || categories.length === 0) {
      return;
    }

    if (categories.length === 1) {
      const projectsInCategory = await getCategoryProjects(categories[0].id);
      components.multiStateBox.changeState(States.Category);
      components.categoryWidget.data = {
        category: categories[0],
        projects: projectsInCategory.map((p) => p.project),
      };
    } else {
      components.multiStateBox.changeState(States.Gallery);
      components.galleryWidget.data = { categories };
    }
  };

  return {
    pageReady: async () => {
      components = getComponents();
      await initPortfolio();
    },
    exports: {},
  };
});
