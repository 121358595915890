
  import {createControllersWithDescriptors, initAppForPageWrapper} from '@wix/yoshi-flow-editor/runtime/esm/viewerScript.js';
  
  
            
const wrapController0 = null;

            import controller0 from '/home/builduser/work/80801144f80bb816/packages/portfolio-bob/src/components/categoriesGalleryWidget/viewer.controller.ts';
            import * as _controllerExport0 from '/home/builduser/work/80801144f80bb816/packages/portfolio-bob/src/components/categoriesGalleryWidget/viewer.controller.ts';
            var controllerExport0 = _controllerExport0;
            

            
const wrapController1 = null;

            import controller1 from '/home/builduser/work/80801144f80bb816/packages/portfolio-bob/src/components/categoriesListWidget/viewer.controller.ts';
            import * as _controllerExport1 from '/home/builduser/work/80801144f80bb816/packages/portfolio-bob/src/components/categoriesListWidget/viewer.controller.ts';
            var controllerExport1 = _controllerExport1;
            

            
const wrapController2 = null;

            import controller2 from '/home/builduser/work/80801144f80bb816/packages/portfolio-bob/src/components/categoryPageWidget/viewer.controller.ts';
            import * as _controllerExport2 from '/home/builduser/work/80801144f80bb816/packages/portfolio-bob/src/components/categoryPageWidget/viewer.controller.ts';
            var controllerExport2 = _controllerExport2;
            

            
const wrapController3 = null;

            import controller3 from '/home/builduser/work/80801144f80bb816/packages/portfolio-bob/src/components/categoryWidget/viewer.controller.ts';
            import * as _controllerExport3 from '/home/builduser/work/80801144f80bb816/packages/portfolio-bob/src/components/categoryWidget/viewer.controller.ts';
            var controllerExport3 = _controllerExport3;
            

            
const wrapController4 = null;

            import controller4 from '/home/builduser/work/80801144f80bb816/packages/portfolio-bob/src/components/navigationWidget/viewer.controller.ts';
            import * as _controllerExport4 from '/home/builduser/work/80801144f80bb816/packages/portfolio-bob/src/components/navigationWidget/viewer.controller.ts';
            var controllerExport4 = _controllerExport4;
            

            
const wrapController5 = null;

            import controller5 from '/home/builduser/work/80801144f80bb816/packages/portfolio-bob/src/components/portfolioPageWidget/viewer.controller.ts';
            import * as _controllerExport5 from '/home/builduser/work/80801144f80bb816/packages/portfolio-bob/src/components/portfolioPageWidget/viewer.controller.ts';
            var controllerExport5 = _controllerExport5;
            

            
const wrapController6 = null;

            import controller6 from '/home/builduser/work/80801144f80bb816/packages/portfolio-bob/src/components/projectHeaderWidget/viewer.controller.ts';
            import * as _controllerExport6 from '/home/builduser/work/80801144f80bb816/packages/portfolio-bob/src/components/projectHeaderWidget/viewer.controller.ts';
            var controllerExport6 = _controllerExport6;
            

            
const wrapController7 = null;

            import controller7 from '/home/builduser/work/80801144f80bb816/packages/portfolio-bob/src/components/projectPageWidget/viewer.controller.ts';
            import * as _controllerExport7 from '/home/builduser/work/80801144f80bb816/packages/portfolio-bob/src/components/projectPageWidget/viewer.controller.ts';
            var controllerExport7 = _controllerExport7;
            

            
const wrapController8 = null;

            import controller8 from '/home/builduser/work/80801144f80bb816/packages/portfolio-bob/src/components/projectWidget/viewer.controller.ts';
            import * as _controllerExport8 from '/home/builduser/work/80801144f80bb816/packages/portfolio-bob/src/components/projectWidget/viewer.controller.ts';
            var controllerExport8 = _controllerExport8;
            

            
const wrapController9 = null;

            import controller9 from '/home/builduser/work/80801144f80bb816/packages/portfolio-bob/src/components/tagsPageWidget/viewer.controller.ts';
            import * as _controllerExport9 from '/home/builduser/work/80801144f80bb816/packages/portfolio-bob/src/components/tagsPageWidget/viewer.controller.ts';
            var controllerExport9 = _controllerExport9;
            

  
  var importedApp = {};


  

    import { initState, initBind } from '@wix/yoshi-flow-editor/runtime/esm/velocycleMobx';
    import { autorun, observable } from '@wix/yoshi-flow-editor/blocks/mobx';
    var velocycleMobx = { initState, initBind, autorun, observable };
    


  

    import { blocksControllerService } from '@wix/yoshi-flow-editor/runtime/esm/blocks/controllerService'
    

  
var createHttpClient = null;

  

  import { initI18n as initI18n } from '@wix/yoshi-flow-editor/runtime/esm/i18next/init';


  

    var createExperiments = null;
    var createWidgetExperiments = null;
    


  var sentryConfig = {
      DSN: 'https://bfb679c754744c58a7374ee6e25cfc13@sentry.wixpress.com/2954',
      id: 'bfb679c754744c58a7374ee6e25cfc13',
      projectName: 'portfolio-bob-xj',
      teamName: 'photography-portfolio',
      
    };

  var experimentsConfig = {"centralized":true,"scopes":[]};

  var translationsConfig = {"icuEnabled":true,"defaultTranslationsPath":"/home/builduser/work/80801144f80bb816/packages/portfolio-bob/src/assets/locales/messages_en.json","availableLanguages":["en","uk"]};

  var defaultTranslations = {"app.widget.welcome":"Welcome","app.settings.label":"Configure Widget","app.settings.tabs.main":"Main","app.settings.tabs.design":"Design","app.settings.defaults.greetingsPretext":"to"};

  var fedopsConfig = null;

  import { createVisitorBILogger as biLogger } from '/home/builduser/work/80801144f80bb816/packages/portfolio-bob/target/generated/bi/createBILogger.ts';

  export const exports = importedApp.exports;

  export const initAppForPage = initAppForPageWrapper({
    initAppForPage: importedApp.initAppForPage,
    sentryConfig: sentryConfig,
    experimentsConfig: experimentsConfig,
    inEditor: false,
    biLogger: biLogger,
    projectName: "portfolio-bob",
    biConfig: null,
    appName: "Wix Portfolio",
    appDefinitionId: "d90652a2-f5a1-4c7c-84c4-d4cdcc41f130",
    fedopsConfig: fedopsConfig,
    translationsConfig: translationsConfig,
    defaultTranslations: defaultTranslations,
    shouldUseEssentials: true,
    optionalDeps: {
      initI18n,
      createHttpClient,
      createExperiments,
    },
    localeDistPath: "assets/locales",
  });

  const _createControllers = createControllersWithDescriptors({
    initI18n,
    blocksControllerService,
    createHttpClient,
    createExperiments,
    velocycleMobx,
  }, [{ method: controller0,
          wrap: wrapController0,
          exports: controllerExport0,
          widgetType: "BLOCKS_WIDGET",
          translationsConfig: translationsConfig,
          experimentsConfig: {"centralized":true,"scopes":[]},
          fedopsConfig: fedopsConfig,
          sentryConfig: sentryConfig,
          persistentAcrossPages: false,
          biLogger: biLogger,
          shouldUseEssentials: true,
          withErrorBoundary: false,
          biConfig: null,
          controllerFileName: "/home/builduser/work/80801144f80bb816/packages/portfolio-bob/src/components/categoriesGalleryWidget/viewer.controller.ts",
          appName: "Wix Portfolio",
          appDefinitionId: "d90652a2-f5a1-4c7c-84c4-d4cdcc41f130",
          projectName: "portfolio-bob",
          componentName: "categoriesGalleryWidget",
          localeDistPath: "assets/locales",
          defaultTranslations: defaultTranslations,
          id: "d90652a2-f5a1-4c7c-84c4-d4cdcc41f130-itb6f" }, { method: controller1,
          wrap: wrapController1,
          exports: controllerExport1,
          widgetType: "BLOCKS_WIDGET",
          translationsConfig: translationsConfig,
          experimentsConfig: {"centralized":true,"scopes":[]},
          fedopsConfig: fedopsConfig,
          sentryConfig: sentryConfig,
          persistentAcrossPages: false,
          biLogger: biLogger,
          shouldUseEssentials: true,
          withErrorBoundary: false,
          biConfig: null,
          controllerFileName: "/home/builduser/work/80801144f80bb816/packages/portfolio-bob/src/components/categoriesListWidget/viewer.controller.ts",
          appName: "Wix Portfolio",
          appDefinitionId: "d90652a2-f5a1-4c7c-84c4-d4cdcc41f130",
          projectName: "portfolio-bob",
          componentName: "categoriesListWidget",
          localeDistPath: "assets/locales",
          defaultTranslations: defaultTranslations,
          id: "d90652a2-f5a1-4c7c-84c4-d4cdcc41f130-waybk" }, { method: controller2,
          wrap: wrapController2,
          exports: controllerExport2,
          widgetType: "BLOCKS_WIDGET",
          translationsConfig: translationsConfig,
          experimentsConfig: {"centralized":true,"scopes":[]},
          fedopsConfig: fedopsConfig,
          sentryConfig: sentryConfig,
          persistentAcrossPages: false,
          biLogger: biLogger,
          shouldUseEssentials: true,
          withErrorBoundary: false,
          biConfig: null,
          controllerFileName: "/home/builduser/work/80801144f80bb816/packages/portfolio-bob/src/components/categoryPageWidget/viewer.controller.ts",
          appName: "Wix Portfolio",
          appDefinitionId: "d90652a2-f5a1-4c7c-84c4-d4cdcc41f130",
          projectName: "portfolio-bob",
          componentName: "categoryPageWidget",
          localeDistPath: "assets/locales",
          defaultTranslations: defaultTranslations,
          id: "d90652a2-f5a1-4c7c-84c4-d4cdcc41f130-voiu8" }, { method: controller3,
          wrap: wrapController3,
          exports: controllerExport3,
          widgetType: "BLOCKS_WIDGET",
          translationsConfig: translationsConfig,
          experimentsConfig: {"centralized":true,"scopes":[]},
          fedopsConfig: fedopsConfig,
          sentryConfig: sentryConfig,
          persistentAcrossPages: false,
          biLogger: biLogger,
          shouldUseEssentials: true,
          withErrorBoundary: false,
          biConfig: null,
          controllerFileName: "/home/builduser/work/80801144f80bb816/packages/portfolio-bob/src/components/categoryWidget/viewer.controller.ts",
          appName: "Wix Portfolio",
          appDefinitionId: "d90652a2-f5a1-4c7c-84c4-d4cdcc41f130",
          projectName: "portfolio-bob",
          componentName: "categoryWidget",
          localeDistPath: "assets/locales",
          defaultTranslations: defaultTranslations,
          id: "d90652a2-f5a1-4c7c-84c4-d4cdcc41f130-os5sm" }, { method: controller4,
          wrap: wrapController4,
          exports: controllerExport4,
          widgetType: "BLOCKS_WIDGET",
          translationsConfig: translationsConfig,
          experimentsConfig: {"centralized":true,"scopes":[]},
          fedopsConfig: fedopsConfig,
          sentryConfig: sentryConfig,
          persistentAcrossPages: false,
          biLogger: biLogger,
          shouldUseEssentials: true,
          withErrorBoundary: false,
          biConfig: null,
          controllerFileName: "/home/builduser/work/80801144f80bb816/packages/portfolio-bob/src/components/navigationWidget/viewer.controller.ts",
          appName: "Wix Portfolio",
          appDefinitionId: "d90652a2-f5a1-4c7c-84c4-d4cdcc41f130",
          projectName: "portfolio-bob",
          componentName: "navigationWidget",
          localeDistPath: "assets/locales",
          defaultTranslations: defaultTranslations,
          id: "d90652a2-f5a1-4c7c-84c4-d4cdcc41f130-zmzxw" }, { method: controller5,
          wrap: wrapController5,
          exports: controllerExport5,
          widgetType: "BLOCKS_WIDGET",
          translationsConfig: translationsConfig,
          experimentsConfig: {"centralized":true,"scopes":[]},
          fedopsConfig: fedopsConfig,
          sentryConfig: sentryConfig,
          persistentAcrossPages: false,
          biLogger: biLogger,
          shouldUseEssentials: true,
          withErrorBoundary: false,
          biConfig: null,
          controllerFileName: "/home/builduser/work/80801144f80bb816/packages/portfolio-bob/src/components/portfolioPageWidget/viewer.controller.ts",
          appName: "Wix Portfolio",
          appDefinitionId: "d90652a2-f5a1-4c7c-84c4-d4cdcc41f130",
          projectName: "portfolio-bob",
          componentName: "portfolioPageWidget",
          localeDistPath: "assets/locales",
          defaultTranslations: defaultTranslations,
          id: "d90652a2-f5a1-4c7c-84c4-d4cdcc41f130-n0thf" }, { method: controller6,
          wrap: wrapController6,
          exports: controllerExport6,
          widgetType: "BLOCKS_WIDGET",
          translationsConfig: translationsConfig,
          experimentsConfig: {"centralized":true,"scopes":[]},
          fedopsConfig: fedopsConfig,
          sentryConfig: sentryConfig,
          persistentAcrossPages: false,
          biLogger: biLogger,
          shouldUseEssentials: true,
          withErrorBoundary: false,
          biConfig: null,
          controllerFileName: "/home/builduser/work/80801144f80bb816/packages/portfolio-bob/src/components/projectHeaderWidget/viewer.controller.ts",
          appName: "Wix Portfolio",
          appDefinitionId: "d90652a2-f5a1-4c7c-84c4-d4cdcc41f130",
          projectName: "portfolio-bob",
          componentName: "projectHeaderWidget",
          localeDistPath: "assets/locales",
          defaultTranslations: defaultTranslations,
          id: "d90652a2-f5a1-4c7c-84c4-d4cdcc41f130-vvhj5" }, { method: controller7,
          wrap: wrapController7,
          exports: controllerExport7,
          widgetType: "BLOCKS_WIDGET",
          translationsConfig: translationsConfig,
          experimentsConfig: {"centralized":true,"scopes":[]},
          fedopsConfig: fedopsConfig,
          sentryConfig: sentryConfig,
          persistentAcrossPages: false,
          biLogger: biLogger,
          shouldUseEssentials: true,
          withErrorBoundary: false,
          biConfig: null,
          controllerFileName: "/home/builduser/work/80801144f80bb816/packages/portfolio-bob/src/components/projectPageWidget/viewer.controller.ts",
          appName: "Wix Portfolio",
          appDefinitionId: "d90652a2-f5a1-4c7c-84c4-d4cdcc41f130",
          projectName: "portfolio-bob",
          componentName: "projectPageWidget",
          localeDistPath: "assets/locales",
          defaultTranslations: defaultTranslations,
          id: "d90652a2-f5a1-4c7c-84c4-d4cdcc41f130-yaafe" }, { method: controller8,
          wrap: wrapController8,
          exports: controllerExport8,
          widgetType: "BLOCKS_WIDGET",
          translationsConfig: translationsConfig,
          experimentsConfig: {"centralized":true,"scopes":[]},
          fedopsConfig: fedopsConfig,
          sentryConfig: sentryConfig,
          persistentAcrossPages: false,
          biLogger: biLogger,
          shouldUseEssentials: true,
          withErrorBoundary: false,
          biConfig: null,
          controllerFileName: "/home/builduser/work/80801144f80bb816/packages/portfolio-bob/src/components/projectWidget/viewer.controller.ts",
          appName: "Wix Portfolio",
          appDefinitionId: "d90652a2-f5a1-4c7c-84c4-d4cdcc41f130",
          projectName: "portfolio-bob",
          componentName: "projectWidget",
          localeDistPath: "assets/locales",
          defaultTranslations: defaultTranslations,
          id: "d90652a2-f5a1-4c7c-84c4-d4cdcc41f130-gpt2l" }, { method: controller9,
          wrap: wrapController9,
          exports: controllerExport9,
          widgetType: "BLOCKS_WIDGET",
          translationsConfig: translationsConfig,
          experimentsConfig: {"centralized":true,"scopes":[]},
          fedopsConfig: fedopsConfig,
          sentryConfig: sentryConfig,
          persistentAcrossPages: false,
          biLogger: biLogger,
          shouldUseEssentials: true,
          withErrorBoundary: false,
          biConfig: null,
          controllerFileName: "/home/builduser/work/80801144f80bb816/packages/portfolio-bob/src/components/tagsPageWidget/viewer.controller.ts",
          appName: "Wix Portfolio",
          appDefinitionId: "d90652a2-f5a1-4c7c-84c4-d4cdcc41f130",
          projectName: "portfolio-bob",
          componentName: "tagsPageWidget",
          localeDistPath: "assets/locales",
          defaultTranslations: defaultTranslations,
          id: "d90652a2-f5a1-4c7c-84c4-d4cdcc41f130-vp33g" }],
    false);

    export const createControllers = _createControllers
