export enum ProjectHeaderWidgetComponentIds {
  Root = '#projectHeaderWidgetRoot',
  Title = '#projectTitle',
  SubTitle = '#projectSubtitle',
  Description = '#projectDescription',
  HeroImage = '#projectHeroImage',
}

export enum ProjectHeaderWidgetEvents {}
// Change = 'change',

export const ProjectHeaderWidgetPresets = [
  {
    id: 'variants-kzwjjh6z1',
    src:
      'https://d1csarkz8obe9u.cloudfront.net/posterpreviews/tropical-background-%2Csummer-background-design-template-77d4868da55f9006156f1cd3d087c8e4_screen.jpg?ts=1594231813',
    layout: {
      width: '250px',
      height: '130px',
    },
  },
  {
    id: 'variants-l0ff8yv5',
    src:
      'https://static.vecteezy.com/system/resources/previews/001/879/897/non_2x/purple-floral-background-with-neon-effects-and-highlights-free-vector.jpg',
    layout: {
      width: '250px',
      height: '130px',
    },
  },
];
