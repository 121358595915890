import model from './model';
import {
  NavigationWidgetComponentIds as ComponentIds,
  NavigationWidgetEvents as Events,
} from './config/constants';
import { NavigationWidgetProps as Props } from './config/types';

export default model.createController((controllerParams) => {
  const { $w, $widget } = controllerParams;

  let components: ReturnType<typeof getComponents>;

  const getComponents = () => ({
    topContainer: $w(ComponentIds.Root),
    nextContainer: $w(ComponentIds.NextContainer),
    nextText: $w(ComponentIds.NextText),
    nextButton: $w(ComponentIds.NextButton),
    backContainer: $w(ComponentIds.BackContainer),
    backText: $w(ComponentIds.BackText),
    backButton: $w(ComponentIds.BackButton),
  });

  const initByProps = (props: Props) => {
    const { data } = props;

    if (data.hideBack) {
      components.backContainer.hide();
    }

    if (data.hideNext) {
      components.nextContainer.hide();
    }
  };

  const initEventHandlers = () => {
    components.nextButton.onClick(() => {
      $widget.fireEvent(Events.nextClicked, {});
    });

    components.backButton.onClick(() => {
      $widget.fireEvent(Events.backClicked, {});
    });
  };

  return {
    pageReady: async () => {
      components = getComponents();
      initEventHandlers();
      initByProps($widget.props);
      $widget.onPropsChanged((_, nextProps) => initByProps(nextProps));
      $widget.fireEvent('widgetLoaded', {});
    },
    exports: {},
  };
});
