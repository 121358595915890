export enum ProjectPageWidgetComponentIds {
  Root = '#projectPageWidgetRoot',
  MultiStateBox = '#projectMultiStateBox',
  ProjectWidget = '#projectWidget',
}

export enum ProjectPageWidgetStates {
  BASIC = 'basic',
  SIDE_BY_SIDE = 'sideBySide',
  SINGLE_PROJECT = 'singleProject',
}
// export enum ProjectPageWidgetStates {
//   One = '1',
//   Two = '2',
//   Three = '3',
// }

export enum ProjectPageWidgetEvents {}
// Change = 'change',
